import {useMediaListStore} from "~/stores/media-list"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const q = getSingleValue(to.query.q)

    if (!q) {
        return navigateTo(useNuxtApp().$localePath('index'), { redirectCode: 301 })
    } else {
        await useMediaListStore().search(q)
    }
})
